<template>
  <div style="padding-left: 100px; padding-right: 100px; ">
	<side-bar-admin/>
  <v-card style="margin: 150px 0px;">
    <v-card-title style="color: var(--vertfonce);">
      Liste des Implants
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table v-if="show == 1"
		:headers="headers"
		:items="tags"
		:items-per-page="5"
		class="elevation-1"
		>
		<!-- ... autres slots ... -->
		<template v-slot:item.action="{ item }">
			<button @click="tag_name = item; dialog = true;" class="button-suppress">Supprimer</button>
		</template>
		<template v-slot:item.email="{ item }">
			<v-select
			:items="item.email"
			label="Emails"
			chips
			small-chips
			dense
			></v-select>
		</template>
	</v-data-table>
  </v-card>
  <v-dialog v-model="dialog" max-width="290">
		<v-card>
			<v-card-title class="headline">Confirmation</v-card-title>
			<v-card-text>
				Êtes-vous sûr de vouloir supprimer le tag {{ tag_name.tag_id }} ? Il sera dépossédé de tous les utilisateurs le possédant
			</v-card-text>
			<v-card-actions>
				<v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
				<v-btn color="blue darken-1" text @click="supressTags">Oui</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
  </div>
</template>



<script>
import Vue from 'vue';
import SideBarAdmin from '../components/SideBarAdmin.vue';
import moment from 'moment';
moment.locale('fr');
	export default {
  components: { SideBarAdmin },
		
		data(){
			return {
				show: 0,
		headers: [
          {
            text: 'id',
            align: 'start',
            value: 'id',
          },
		  { text: 'tag_id', value: 'tag_id' },
		  { text: 'Suppression', value: 'action' },
		  { text: 'mail', value: 'email' },
		  {text: 'date dernière données reçues', value: 'last_used'}
        ],

        tags: [
          {
			id: 1,
			tag_id: 'exampleTag',
			email: ['john@example.com', 'jane@example.com', 'doe@example.com'],
			last_used: 0,
			},
        ],
		dialog: false,
		tag_name: ''
			}
		},

    async mounted(){
			await this.GetTags();
			await this.getDatafor();
		},

		methods: {
			
           async GetTags(){
            const url = this.$api.getRESTApiUri() + `/get-tag-id`;
            fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
            })
            .then(res => res.text())
            .then((result) => {
				this.tags = JSON.parse(result)
				console.log(JSON.parse(result))
				console.log(this.tags)
				this.show = 1;
            })
			.catch((error) => {
				console.log(error)
			});
			},
			async getDatafor(){
				console.log(this.tags.tag_id)
				for(let i = 0; i < this.tags.length; i++){
					await this.getLastUsed(i)
				}
				this.tags = [...this.tags];
			},
			async getLastUsed(i) {
				const tag_id_name = encodeURIComponent(this.tags[i].tag_id);
				console.log(this.tags[i].tag_id)
				const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id_name}`;
				return fetch(url)
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					const formattedDate= this.formatDate(data[0]?.received_on);
					Vue.set(this.tags[i], 'last_used', formattedDate);
				})
				.catch((error) => {
					console.log(error)
				});
			},
			formatDate(date) {
				return moment(date).format('LL, LT');
			},
			async supressTags() {
				console.log(this.tag_name);

				const url = this.$api.getRESTApiUri() + `/admin/supress-tags`;

				try {
					const response = await fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							tag_id: this.tag_name.tag_id,
							email: this.tag_name.email,
						}),
					});

					if (response.ok) {
						console.log('Tag deleted successfully');

						// Si la suppression a réussi, retirez le tag du tableau tags.
						this.tags = this.tags.filter((tag) => tag.tag_id !== this.tag_name.tag_id);

						// Fermez la boîte de dialogue.
						this.dialog = false;
					} else {
						console.error('Erreur lors de la suppression du tag:', response.status);
						// Gérez l'erreur si nécessaire
					}
				} catch (error) {
					console.error('Erreur lors de la suppression du tag:', error);
					// Gérez l'erreur si nécessaire
				}
			},

		}
	}
</script>

<style>

.button-suppress{
	justify-content: center;
	background-color: firebrick;
	border: solid 1px firebrick;
	padding: 4px 8px;
	border-radius: 5px;
	color: white;
	transition: 0.5s;
}

.button-suppress:hover{
	background-color: white;
	border: solid 1px firebrick;
	color: firebrick;
	transition: 0.5s;
}

</style>