<template>
	<!-- SideBar -->
	<v-navigation-drawer app light expand-on-hover fixed permanent style="z-index:1001; background-color: var(--grisclair);">

		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-menu</v-icon>
			</v-list-item-icon>
			<v-list-item-title class="title">
				Menu
			</v-list-item-title>
		</v-list-item>

		<v-divider></v-divider>

		<v-list>
			<v-list-item 
				v-for="item in items" 
				:key="item.title" 
				:to="item.route" 
				link
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<template v-slot:append>
			<v-list>
				<v-list-item :to="{ path: `/home` }">
					<v-list-item-icon>
						<v-icon>mdi-table-large</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title >Back To User</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: 'SideBarAdmin',
		data() {
			return {
				items: [
					
					{
						title: 'Liste des utilisateurs',
						icon: 'mdi-account-box-outline',
						route: '/admin/liste-utilisateurs',
					},
					{
						title: 'Liste des tags',
						icon: 'mdi-format-list-bulleted',
						route: '/admin/liste-tags',
					},
					{
						title: 'Création d\'un token',
						icon: 'mdi-qrcode-scan',
						route: '/admin/register-token-creation',
					},
					{
						title: 'Creation Attribution',
						icon: 'mdi-arrow-up-bold-circle-outline',
						route: '/admin/creation-activation',
					},
				],
				right: null,
			}
		},
	};
</script>